import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '../../components/SEO';
import Layout from '../../components/layout';
import { ContactFormSidebar } from '../../components/contactFormSidebar';
import { okBaseUrl } from '../../utils/config.js';
import bg from '../../images/bg_image_trainings_page.jpg';
import logo from '../../images/Logo@2x.png';

export default props => {
  const { data, location } = props;
  const courseList = data.allPagecontentJson;
  const allCourses = data.allCoursesJson;
  const post = data.markdownRemark;

  return (
    <>
      <SEO
        title="Excel-verkkokurssit"
        description="Joustavaan, työelämässä tarvittavien Excel-taitojen kasvattamiseen."
      />
      <Layout
        mainTitle={'Excel-verkkokurssit'}
        mainTitleClasses={'mt-3'}
        additionalTitle={'Työelämän tarpeisiin'}
        headerBgStyles={{ background: 'url(' + bg + ')' }}
        headerTitleStyle={{ color: '#0086ef' }}
        headerAdditionalTitleStyle={{ color: '#019999' }}
        additionalTitleClassNames={'mb-3 mt-md-0 mb-md-3'}
        headerNavLinkStyle={{ color: '#7a7c81' }}
        logo={logo}
        {...props}
      >
        <div className="container">
          <div className="row d-flex justify-content-center pt-0 pb-3 pb-md-4">
            <div className="col-lg-6 col-md-6 pt-5 pr-md-6 mb-2">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.onlineCoursesSnippet.html,
                }}
              />
            </div>
            <div className="col-lg-5 col-md-6">
              <div
                className={
                  'shadow shadow-except-mobile pt-3 pt-md-5 px-sm-4 pb-3'
                }
              >
                <ContactFormSidebar
                  title={'Ota yhteyttä tai jätä tarjouspyyntö'}
                  url={`${okBaseUrl}/fi/ota-yhteytta-iframe`}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query onlineCourses {
    onlineCoursesSnippet: markdownRemark(
      frontmatter: { type: { eq: "snippet" }, id: { eq: "online-courses" } }
    ) {
      html
    }
  }
`;
