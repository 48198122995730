import React from 'react';
import PropTypes from 'prop-types';
import loaderImage from '../images/ajax-loader.gif';

export function ContactFormSidebar({ title, url }) {
  return (
    <>
      <h2 className="contact-us text-center mb-5">{title}</h2>
      <div className="iframeWrapper contact">
        Ota yhteyttä tai jätä tarjouspyyntö lähettämällä sähköpostia
        osoitteeseen <b>officekoulutus@gmail.com</b>
      </div>
    </>
  );
}

ContactFormSidebar.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
